import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Az oldal nem található | sziklaybence.com
			</title>
			<meta name={"description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!"} />
			<meta property={"og:title"} content={"Az oldal nem található"} />
			<meta property={"og:description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/creativeOG.webp?v=2024-08-06T08:29:54.434Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
		</Helmet>
		<Components.NavBar />
		<Section
			min-height="100vh"
			background="url(https://images.unsplash.com/photo-1610337673044-720471f83677?auto=format&fit=crop&w=1500&q=80) center center/cover no-repeat"
			md-background="url(https://images.unsplash.com/photo-1610337673044-720471f83677?auto=format&fit=crop&w=800&q=80) center center/cover no-repeat"
			padding="88px 0 88px 0"
			quarkly-title="404-7"
		>
			<Override slot="SectionContent" justify-content="center" max-width="1220px" align-items="center" />
			<Box
				border-radius="24px"
				display="flex"
				justify-content="center"
				padding="56px 106px 64px 106px"
				background="--color-dark"
				max-width="600px"
				sm-padding="26px 30px 34px 30px"
				align-items="center"
				flex-direction="column"
			>
				<Text
					font="--headline1"
					color="--light"
					margin="0px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					as="h1"
				>
					404
				</Text>
				<Text
					md-width="100%"
					font="--lead"
					color="--lightD1"
					margin="0px 0px 40px 0px"
					text-align="center"
					width="100%"
					lg-width="80%"
				>
					Az oldal, amit keresel, nem elérhető, vagy nem tartozik ehhez a weboldalhoz!
				</Text>
				<Link
					font="--lead"
					padding="12px 24px 12px 24px"
					border-radius="8px"
					background="--color-light"
					href="#"
					text-decoration-line="initial"
					color="--dark"
				>
					Kezdőoldal
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});